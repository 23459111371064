import React, { useEffect, useState, SetStateAction, Dispatch } from "react";
import "./modal.css";
import DataGrid from '../table/data_grid';
import SelectedListItem from "../list/nodes_list";
import {JrpcServer} from "../../jrpc/server/0_0_1/types";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Paper from '@mui/material/Paper';
import { INodeCardData, INodeData } from "../common/types/nodeData";
import { IconButton, MenuItem, Select, Stack } from "@mui/material";
import { Cancel, Menu } from "@mui/icons-material";
import { NodeCardMenu } from "../nodeCardMenu/nodeCardMenu";

interface IModalProps { 
    modalNodeData: INodeCardData
    setActive: Dispatch<SetStateAction<INodeCardData>>
    jRPCServer: JrpcServer
    openedNodes: INodeData[]
    selectedNode: number
    setSelectedNode: Dispatch<SetStateAction<number>>
    removeNode: (index: number, currentIndex: number)=>void
    onSelectChange: (id: number) => void
}

const generateUniqueId = () => {
    const timestamp = Date.now().toString(36);
    const randomNumber = Math.random().toString(36).substr(2);
    return timestamp + randomNumber;
};

const createTableRows = (data: object): any[] => {
    if (!data) {
        return [];
    }
    return Object.entries(data).map(([name, data]) => ({id: generateUniqueId(), name, data }));
};

const setMaxStrLength = (text: string,value: number = 30) => {
    if (text.length > value) {
        return text.slice(0,value)+"..."
    }
    return text
}

const Modal = (props: IModalProps) => {
    const { modalNodeData, setActive, jRPCServer, openedNodes, selectedNode, removeNode, onSelectChange: handleNodeSelectChange} = props;
    const [rows, setRows] = useState<any[]>([]);
    const isMobile = window.innerWidth < 600;
    const windowWidth = window.innerWidth;

    const setVisibleFalse = () => {
        setActive({...modalNodeData, visible: false});
    }
    
    function escFunction (ev: KeyboardEvent):void {
        if (ev.key === "Escape") {
            setVisibleFalse();}
    }

    useEffect(() => {
        if (!modalNodeData.isGroup) {
            const newRows = createTableRows(modalNodeData.nodeInfo);
            setRows(newRows);
        }
    }, [modalNodeData]);
    useEffect(() => {
        document.addEventListener("keydown", escFunction, true);
        return () => (
            document.removeEventListener("keydown", escFunction, true)
        )
    })

    let header = null;
    let data_part = null;
    const additionalColumns = [
        { 
            field: 'name', 
            headerName: 'Наименование', 
            editable: true,
            width: isMobile? windowWidth*.3-8:150,
        },
        {
          field: 'data',
          headerName: 'Данные',
          editable: true,
          width: isMobile? windowWidth*.7-8:400,
        },
    ];

    header = (
        <div className="node_header">
            <div>
            <IconButton size="medium" onClick={() => setVisibleFalse()}><ArrowBackIosIcon fontSize="inherit"/></IconButton>
            </div>
            <div style={{width: "100%"}}>
            <Select 
                id="selectCardDropdown"
                size="small" value={selectedNode || ''} 
                sx={{color: "black", marginBlockEnd: 1}} 
                fullWidth={true} 
                onChange={(e) => {handleNodeSelectChange(e.target.value as number);}}
                renderValue={(value) => setMaxStrLength(openedNodes[value as number]?.name || "", 30)}
                >
                    {openedNodes.map((node, indx)=> 
                        (!! indx &&
                        <MenuItem value={indx} key={node.name} sx={{display: "flex", justifyContent: "space-between", fontSize: isMobile ? ".8rem" : "1em"}} selected={indx===selectedNode}>
                            {setMaxStrLength(node.name, 35)}
                            <IconButton aria-label="delete" onClick={(e)=>{
                                e.preventDefault(); 
                                e.stopPropagation(); 
                                removeNode(indx, selectedNode)
                                }}>
                                <Cancel fontSize="small" sx={{padding: 0}}/>
                            </IconButton>
                        </MenuItem>
                        )
                    )}
                </Select>
                </div>
                <div><NodeCardMenu nodeName={openedNodes[selectedNode]?.name || ""} jrpcServerClient={jRPCServer} /></div>
        </div>
    )

    if (modalNodeData.isGroup) {
        data_part = (
            <div className="data-part group">
                <SelectedListItem data={modalNodeData} jRPCServer={props.jRPCServer}/>
            </div>
        )
    } else {
        data_part = (
            <div className="data-part group">
                <DataGrid
                    rows={rows}
                    setRows={setRows}
                    additionalColumns={additionalColumns}
                    onRemoveNodeAttr={(attrName)=>{jRPCServer.removeNodeAttr({node_name: modalNodeData.nodeName, attr_name: attrName})}}
                    onSetNodeAttr={(attrName, attrValue) => {jRPCServer.setNodeAttr({node_name: modalNodeData.nodeName, attr_name: attrName, attr_value: attrValue})}}
                />
            </div>
        )
    }

    return (
        <Paper className={`modal ${modalNodeData.visible ? 'active' : ''}`}>
            <div className="modal__content" onClick={e => e.stopPropagation()}>
                {header}
                {data_part}
            </div>
        </Paper>
    );
};

export default Modal;