import { IconButton, Menu, MenuItem } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { NestedMenuItem } from "mui-nested-menu";
import { JrpcServer } from "../../jrpc/server/0_0_1/types";
import { Menu as MenuIcon } from "@mui/icons-material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

interface INodeCardMenuAction {
  type: string;
  data: {
    id: string;
  };
}

export interface INodeCardMenuItem {
  text: string;
  type: string;
  tooltip?: string;
  enabled: boolean;
  tooltips_visible: boolean;
  children?: INodeCardMenuItem[];
  action?: INodeCardMenuAction;
}

interface NodeCardMenuProps {
  nodeName: string;
  jrpcServerClient: JrpcServer;
}

const theme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          "@media (max-width:600px)": {
            fontSize: "12px",
            fontWeight: "500"
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          "@media (max-width:600px)": {
            fontSize: "12px",
            fontWeight: "500"
          },
        },
      },
    },
  },
});

export const NodeCardMenu = (props: NodeCardMenuProps) => {
  const { nodeName, jrpcServerClient } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [menuItemsData, setMenuItemsData] = useState<INodeCardMenuItem[]>([]);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setAnchorEl(null);
    async function fetchMenu() {
      const menuData = await jrpcServerClient.getCardMenu({
        node_name: nodeName,
      });
      const menuItems = menuData?.data || [];
      setMenuItemsData([...menuItems]);
    }
    fetchMenu();
  }, [nodeName]);

  const callFunction = (actionId: string) => {
        console.log(actionId)
        jrpcServerClient.callCardMenuAction({actionId: actionId})
        handleClose();
    };
  const buildMenuItems = (data: INodeCardMenuItem[]): ReactNode => {
    return (
      <ThemeProvider theme={theme}>

        {data.map((item) => {
          if (item.type === "menu" && item.children?.length) {
            return (
              <NestedMenuItem delay={200} label={item.text} parentMenuOpen={open} sx={{fontSize: "5px"}}>
                {buildMenuItems(item.children)}
              </NestedMenuItem>
            );
          } else if (item.type === "action") {
            return (
              <MenuItem
                onClick={() => callFunction(item.action?.data.id || "")}
              >
                {item.text}
              </MenuItem>
            );
          }
          return <MenuItem>void</MenuItem>;
        })}
    </ThemeProvider>
    );
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {buildMenuItems(menuItemsData)}
      </Menu>
    </>
  );
};
