import * as React from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function Notification(
    props: {
        info: any,
        changeNotificationState: any
    }
) {
    const {info, changeNotificationState} = props;
    const handleClose = () => {
        changeNotificationState(
            {
                open: false,
                text: '',
                code: 0,
            }
        );
    };

    return (
        <Box sx={{width: 500}}>
            <Snackbar
                open={info.open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={10000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={
                        info.code === 1
                            ? 'error'
                            : info.code === 2
                            ? 'warning'
                            : info.code === 3
                            ? 'success'
                            : 'info'
                    }
                    sx={{width: '100%'}}
                >
                    {info.text}
                </Alert>
            </Snackbar>
        </Box>
    );
}